/**
 *
 * /DropDownMenu
 *
 */

import React, { memo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// import gql from 'graphql-tag'
// import useQuery from 'components/UseQuery'
// import { useMutation } from '@apollo/client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useOnClickOutside } from 'hooks'

const Menu = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 10090;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 1px solid rgba(50, 50, 58, 0.15);
	border-radius: 0.25rem;
	max-height: 120px;
	overflow-y: auto;

	.list-unstyled {
		margin: 0;
	}

	ul > li {
		display: block;
		width: 100%;
		padding: 0.25rem 1.5rem;
		clear: both;
		font-weight: 400;
		color: #212529;
		text-align: inherit;
		white-space: nowrap;
		background-color: transparent;
		border: 0;

		:hover {
			background-color: rgba(50, 50, 58, 0.05);
		}
		button {
			text-align: left;
		}
	}
`

const menu_position = {
	right: {
		left: 0,
		float: 'right',
	},
	left: {
		right: 0,
		float: 'left',
	},
}

function DropDownMenu({
	Button,
	close_on_click,
	className,
	menu_style,
	children,
	close_on_change,
	position = 'left',
	onClose,
	disabled,
	button_style,
}) {
	const [open, setOpen] = useState(false)
	const menu_ref = useRef()
	useOnClickOutside(menu_ref, () => setOpen(false))

	useEffect(() => {
		setOpen(false)
	}, [close_on_change])

	useEffect(() => {
		if (onClose && !open) onClose()
	}, [open])

	return (
		<span className={`p-relative ${className}`}>
			<button
				type="button"
				className="btn-unstyled"
				onClick={() => setOpen(!open)}
				disabled={disabled}
				style={button_style}
			>
				{Button || <FontAwesomeIcon icon="ellipsis-h" className="mx-1" />}
			</button>
			{open && (
				<Menu
					style={{
						...(menu_position[position] || {}),
						...menu_style,
					}}
					ref={menu_ref}
					onClick={() => {
						if (close_on_click) setOpen(false)
					}}
				>
					{children}
				</Menu>
			)}
		</span>
	)
}

DropDownMenu.propTypes = {
	Button: PropTypes.node,
	onClose: PropTypes.func,
	close_on_click: PropTypes.bool,
	menu_style: PropTypes.objectOf(PropTypes.any),
	button_style: PropTypes.objectOf(PropTypes.any),
	className: PropTypes.string,
	children: PropTypes.any,
	close_on_change: PropTypes.any, // The menu closes if the value of this prop changes, regardless of what it is.
	position: PropTypes.string,
	disabled: PropTypes.bool,
}

DropDownMenu.defaultProps = {
	close_on_click: true,
	menu_style: {},
}

export default memo(DropDownMenu)
