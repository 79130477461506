/* stylelint-disable no-descending-specificity */
/**
 *
 * Sidebar
 *
 */

import React, { memo, useState, useEffect } from 'react'

// NPM Libraries
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedNumber } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { getMonth } from 'date-fns'
import { useForceUpdate } from 'hooks/useForceUpdate'
import { useCurrentUser } from 'currentUserContext'

// GraphQL
import client from 'configureApolloClient'
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import ChangeLog from 'components/ChangeLog'
import LogoutButton from 'components/LogoutButton'
import Modal from 'components/Modal'
import Molecule from 'components/Molecule'
import NotificationCenter from 'components/Notifications/NotificationCenter'
import SmallLogo from 'images/favicons/apple-icon-180x180.png'
// Don't we love tree shaking? Yes we do =D
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard'
import { faHandshake } from '@fortawesome/pro-regular-svg-icons/faHandshake'
import { faThLarge } from '@fortawesome/pro-solid-svg-icons/faThLarge'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn'
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker'
import { faFolder } from '@fortawesome/pro-solid-svg-icons/faFolder'
import { faCogs } from '@fortawesome/pro-solid-svg-icons/faCogs'
import { COLORS } from 'global-styles'

export const sidebar_width = 209

const SidebarToggle = styled.button`
	position: fixed;
	left: ${sidebar_width - 16}px;
	top: 1.5rem;
	transform: rotate(0);
	cursor: pointer;
	display: inline-block;
	width: 32px;
	height: 32px;
	text-align: center;
	border-radius: 50%;
	border: none;
	outline: none;
	transition: transform 250ms;
	z-index: 1000;
`

const LogoWrapper = styled.div``
const SmallLogoWrapper = styled.div``

const LeftNav = styled.ul`
	color: ${COLORS.grey};
	padding: 0;

	li {
		position: relative;
		font-size: 1rem;
		white-space: nowrap;

		list-style: none;

		&::before {
			content: '';
			height: 100%;
			position: absolute;
			width: 0.3em;
			left: -0.3rem;
			transition: 250ms;
			background-color: ${COLORS.primary};
		}

		a {
			display: block;
			padding: 1em 1em 1em 1.25em;
			color: ${COLORS.grey};
			cursor: pointer;

			.fa,
			.svg-inline--fa {
				position: absolute;
				right: 1em;
				top: 50%;
				transform: translateY(-57%);
				color: ${COLORS.primary};
			}

			&:hover {
				background-color: ${COLORS.primary};
				color: ${COLORS.white};
				text-decoration: none;

				.fa,
				.svg-inline--fa {
					color: ${COLORS.white};
				}
			}
		}

		&.has-sub {
			&:after {
				/* Font Awesome Stuff */
				font: normal normal normal 14px/1 FontAwesome;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				/* FA Chevron Down */
				content: '\f078';

				color: ${COLORS.grey};
				font-size: 0.75rem;

				position: absolute;
				top: 0.6em;
				right: 0;
			}
		}

		&.active {
			&:before {
				left: 0;
			}

			&.has-sub:after {
				/* Flip the chevron up */
				transform: rotate(180deg);
			}

			.sub-menu {
				overflow: visible;
				max-height: 800px;
			}
		}

		.sub-menu {
			max-height: 0;
			overflow: hidden;

			padding-left: 1em;
			list-style-type: none;
			border-left: 2px solid ${COLORS.primary};

			li {
				font-size: 1rem;
				margin: 0.75em 0;
			}
		}
	}
`

const SidebarComponent = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	overflow-y: scroll;
	overflow-x: scroll;

	box-sizing: content-box;

	width: ${sidebar_width}px;
	z-index: 999;

	background-color: #fff;
	color: ${COLORS.primary};

	transition: transform 250ms;

	${LogoWrapper},
	${SmallLogoWrapper} {
		padding: 1rem;
		padding-bottom: 0.5rem;
	}

	${SmallLogoWrapper} {
		display: none;
	}

	#footer {
		color: ${COLORS.grey};
	}

	&::-webkit-scrollbar {
		width: 2px;
		height: 0;
	}

	&::-webkit-scrollbar,
	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb {
		background: transparent;
	}

	&:hover {
		&::-webkit-scrollbar-track {
			background: #ddd;
		}

		&::-webkit-scrollbar-thumb {
			background: #666;
		}
	}
`

const ScrollbarWrapper = styled.div`
	max-height: 100%;
	padding-bottom: 100px;
`

const NotificationCenterComponent = styled.div`
	margin: 2rem auto 1rem;
	text-align: center;

	.notification-list {
		text-align: left;
	}
`

const COLLAPSED_SIDEBAR = css`
	body.sidebar-collapsed {
		${LogoWrapper} {
			display: none;
		}

		${SmallLogoWrapper} {
			display: block;

			img {
				width: 36px;
				position: relative;
				left: 144px;
				top: -3px;
			}
		}

		${SidebarToggle} {
			transform: translateX(-159px);
			top: 1rem;
		}

		${SidebarComponent} {
			transform: translateX(-159px);
		}

		${NotificationCenterComponent} {
			position: absolute;
			right: -3px;
			width: 50px;

			button {
				margin-bottom: 0.8rem;
				margin-right: 0.2rem;
				.badge {
					right: 15px;
				}
			}
		}

		#footer {
			display: none;
		}

		${LeftNav} li.active {
			border-right: 0.2em solid theme-color('primary');
			box-sizing: content-box;
		}

		#content {
			margin-left: 50px;
		}

		.billing-portal-link {
			text-align: right !important;

			a {
				margin-right: 7.5px;
				span {
					display: none;
				}
			}
		}
	}
`
const GET_MENU_ITEMS = gql`
	query menu_items($location: String) {
		menu_items(location: $location)
	}
`

const GET_FOLDING_STATS = gql`
	{
		folding_stats
	}
`

const format_menu_items_sections = (items) => {
	let new_sections = []

	const default_menu_items_sections = [
		['Dashboard', 'Communication Center', 'Confirmation Center'],
		['Campaigns', 'Events'],
		['Contacts', 'Follow-Ups', 'Appointments'],
		['Users', 'Preferences'],
		['Organizations', 'Control Panel'],
	]

	new_sections = default_menu_items_sections.map((section_items) => {
		let new_section_items = []

		section_items.forEach((item_name) => {
			const item = items.find((i) => i.label === item_name)

			if (!!item) {
				new_section_items = [...new_section_items, item]
			}
		})

		return new_section_items
	})

	return new_sections
}

function Sidebar({ user_id, default_menu_items }) {
	const location = useLocation()
	const [show_folding, setShowFolding] = useState(false)
	const [show_change_log, setShowChangeLog] = useState(false)
	const [cached_menu_items, setCachedMenuItems] = useState(format_menu_items_sections(default_menu_items))
	const { forceUpdate } = useForceUpdate()
	const current_user = useCurrentUser()

	// Toggle sidebar collapse
	const collapseSidebar = () => {
		document.body.classList.toggle('sidebar-collapsed')
	}

	const {
		data: { menu_items },
	} = useQuery(GET_MENU_ITEMS, {
		variables: {
			location: location.pathname,
		},
	})

	useEffect(() => {
		if (menu_items && menu_items.length) {
			setCachedMenuItems(format_menu_items_sections(menu_items))
		}
	}, [JSON.stringify(menu_items)])

	const {
		data: { folding_stats },
	} = useQuery(GET_FOLDING_STATS, {
		notifyOnNetworkStatusChange: true,
		skip: true,
	})

	let logo_url = 'https://s3.amazonaws.com/leadjig-resources/images/LeadJig+Black+Text.png'
	let alt_text = 'LeadJig'
	if (process.env.ENV === 'development') {
		logo_url = 'https://s3.amazonaws.com/leadjig-resources/images/LeadJig+Black+Text+Grey%402x.png'
	} else if (process.env.ENV === 'stage') {
		logo_url = 'https://s3.amazonaws.com/leadjig-resources/images/LeadJig+Black+Text+Red%402x.png'
	} else {
		const month = getMonth(new Date())

		if (month === 11) {
			logo_url = 'https://s3.amazonaws.com/leadjig-resources/images/LeadJig+CandyCane+Logo+1.svg'
			alt_text = 'Happy Holidays from LeadJig'
		} else if (month === 4 || month === 5) {
			if (current_user.role >= 50) {
				logo_url = 'https://leadjig-resources.s3.amazonaws.com/images/LeadJig+Lightning.svg'
				alt_text = 'Go Bolts!'

				if (
					[
						'ee232e0f-6977-4737-904a-7ca31ba98ad8', // Danny
						'21fc2422-12d9-49c2-a751-87f9cb580265', // Ryan
						'57b974af-46df-438d-b136-38d41cdfec70', // Alex
						'b8fc22d9-fd6b-4cba-b0b0-21dc116fa57f', // Alicia
					].some((id) => id === current_user.id)
				) {
					logo_url = 'https://leadjig-resources.s3.amazonaws.com/images/LeadJig+Hurricanes.svg'
					logo_url = 'https://leadjig-resources.s3.amazonaws.com/images/LeadJig+Chad.svg'
					alt_text = "Let's Go Canes!"
				}

				if (current_user.id === 'ee232e0f-6977-4737-904a-7ca31ba98ad8') {
					logo_url = 'https://s3.amazonaws.com/leadjig-resources/images/LeadJig+Black+Text.png'
					alt_text = 'LeadJig'
				}
			}
		}
	}

	return (
		<div>
			<SidebarToggle
				id="sidebar-toggle"
				className="bg-primary text-white"
				onClick={collapseSidebar}
				type="button"
			>
				<FontAwesomeIcon icon="bars" fixedWidth />
			</SidebarToggle>
			<div id="sidebar-wrapper">
				<SidebarComponent id="sidebar" className="sidebar" onTransitionEnd={forceUpdate}>
					<LogoWrapper>
						<button className="btn-unstyled w-100" type="button" onClick={() => client.resetStore()}>
							<img className="img-fluid" src={logo_url} alt={alt_text} />
						</button>
					</LogoWrapper>

					<SmallLogoWrapper>
						<img className="img-fluid" src={SmallLogo} alt="LeadJig" />
					</SmallLogoWrapper>

					{/* begin sidebar scrollbar */}
					<ScrollbarWrapper data-scrollbar="true" data-height="100%">
						{/*  begin sidebar nav */}
						<LeftNav>
							{cached_menu_items.map((section, index) => (
								<div
									key={index}
									style={{
										borderBottom: section.length > 0 ? `2px solid ${COLORS.backgroundGrey}` : '',
									}}
								>
									{section.map((item) => (
										<li key={item.label} className={item.active ? 'active' : ''}>
											<Link
												to={item.link}
												onClick={() => {
													if (location.pathname === item.link.split('#')[0]) {
														client.resetStore()
													}
												}}
											>
												<FontAwesomeIcon icon={item.icon} fixedWidth className="float-right" />
												<span>{item.label}</span>
											</Link>
										</li>
									))}
								</div>
							))}
						</LeftNav>
						{/*  end sidebar nav */}
						<div className="billing-portal-link text-center mb-3">
							<a className="btn btn-sm btn-primary" target="_blank" href="https://app.acquiredirect.com">
								<span className="mr-2">Acquire Billing Portal</span>
								<FontAwesomeIcon icon="share-square" />
							</a>
						</div>

						<NotificationCenterComponent>
							<NotificationCenter
								title="Actionable Items"
								icon="envelope"
								unread_count={0}
								actionable
								user_id={user_id}
							/>
							<NotificationCenter
								title="Notifications"
								icon="bell"
								unread_count={0}
								actionable={false}
								user_id={user_id}
							/>
						</NotificationCenterComponent>

						<div className="text-center mb-3">
							<LogoutButton />
						</div>

						{/* <!-- begin #footer --> */}
						<div id="footer" className="footer text-center">
							<small>
								&copy;
								{`2016-${new Date().getFullYear()} `}
								<strong>LeadJig</strong>
								<br />
								All Rights Reserved
								<br />
								<a
									target="_blank"
									href="https://leadjig-resources.s3.amazonaws.com/terms-and-conditions.html"
								>
									Terms and Conditions
								</a>
								<br />
								<a
									target="_blank"
									href="https://leadjig-resources.s3.amazonaws.com/privacy-policy.html"
								>
									Privacy Policy
								</a>
								<br />
								<button className="btn-unstyled" type="button" onClick={() => setShowChangeLog(true)}>
									Build: {process.env.ENV[0].toUpperCase()}.{process.env.BUILD_NUMBER}
								</button>
								<br />
								&nbsp;
								{folding_stats && (
									<React.Fragment>
										<FormattedNumber value={folding_stats.credit} /> Points
										<br />
										<FormattedNumber value={folding_stats.wus} /> Work Units
										<br />
										{/* eslint-disable-next-line react/style-prop-object */}
										Top <FormattedNumber value={folding_stats.percentile} style="percent" />
										&nbsp;
										<button
											className="btn-unstyled"
											type="button"
											onClick={() => setShowFolding(true)}
										>
											?
										</button>
										<br />
										<Modal
											show={show_folding}
											modalDidClose={() => setShowFolding(false)}
											style={{
												height: '100%',
												width: '100%',
												backgroundImage:
													'linear-gradient(-90deg, #397ae9 15%, #4789e1 24%, #adf2ab 140%)',
											}}
										>
											<Molecule
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													bottom: 0,
													width: '100%',
													height: '100%',
													zIndex: 1,
												}}
											/>
										</Modal>
									</React.Fragment>
								)}
								<a href={`${process.env.API_URL}/clear-cache`}>Clear Cache</a>
							</small>
						</div>
					</ScrollbarWrapper>
				</SidebarComponent>
			</div>
			<Global styles={COLLAPSED_SIDEBAR} />
			<Modal show={show_change_log} modalDidClose={() => setShowChangeLog(false)} style={{ width: 900 }}>
				<ChangeLog />
			</Modal>
		</div>
	)
}

Sidebar.propTypes = {
	user_id: PropTypes.string,
	default_menu_items: PropTypes.arrayOf(PropTypes.object),
}

Sidebar.defaultProps = {
	default_menu_items: [
		{
			label: 'Dashboard',
			active: false,
			link: '/',
			icon: faThLarge,
		},
		{
			label: 'Contacts',
			active: false,
			link: '/contacts',
			icon: faAddressCard,
		},
		{
			label: 'Follow-Ups',
			active: false,
			link: '/follow_ups',
			icon: faHandshake,
		},
		{
			label: 'Appointments',
			active: false,
			link: '/appointments',
			icon: faClock,
		},
		{
			label: 'Campaigns',
			active: false,
			link: '/campaigns',
			icon: faBullhorn,
		},
		{
			label: 'Events',
			active: false,
			link: '/events',
			icon: faMapMarker,
		},
		{
			label: 'Content Center',
			active: false,
			link: '/content-center#contact-email-templates',
			icon: faFolder,
		},
		{
			label: 'Preferences',
			active: false,
			link: '/users/preferences#account',
			icon: faCogs,
		},
	],
}

export default memo(Sidebar)
